import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bs-theme"
export default class extends Controller {

  initialize() {
    // First things first, set the current theme on the <html> element using
    // a data attribute.
    this.setTheme(this.getPreferredTheme())

    // Add an event listener that listens to color changes at the OS level.
    // I.e. the user switches their system setting from light to dark.
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.handleSystemColorChange)

    this.showActiveTheme(this.getPreferredTheme())
  }

  getStoredTheme = () => localStorage.getItem('theme')
  setStoredTheme = theme => localStorage.setItem('theme', theme)

  getPreferredTheme = () => {
    const storedTheme = this.getStoredTheme()
    if (storedTheme) {
      return storedTheme
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }

  // Set the data-bs-theme data attribute on the <html> element.
  setTheme = theme => {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  handleSystemColorChange = ()=> {
    const storedTheme = this.getStoredTheme()
    if (storedTheme !== 'light' && storedTheme !== 'dark') {
      this.setTheme(this.getPreferredTheme())
    }
  }

  updateTheme = (e) => {
    const theme = e.currentTarget.getAttribute('data-bs-theme-value')
    this.setStoredTheme(theme)
    this.setTheme(theme)
    this.showActiveTheme(theme, true)
  }

  showActiveTheme = (theme, focus = false) => {
    const themeSwitcher = document.querySelector('#bs-theme')

    // Sanity check
    if (!themeSwitcher) {
      return
    }

    const themeSwitcherText = document.querySelector('#bs-theme-text');
    const activeThemeIcon = document.querySelector('.theme-icon-active use')
    const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`)
    const svgOfActiveBtn = btnToActive.querySelector('svg use').getAttribute('href')

    document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
      element.classList.remove('active')
      element.setAttribute('aria-pressed', 'false')
    })

    btnToActive.classList.add('active')
    btnToActive.setAttribute('aria-pressed', 'true')
    activeThemeIcon.setAttribute('href', svgOfActiveBtn)
    const themeSwitcherLabel = `${themeSwitcherText.textContent} (${btnToActive.dataset.bsThemeValue})`
    themeSwitcher.setAttribute('aria-label', themeSwitcherLabel)

    if (focus) {
      themeSwitcher.focus()
    }
  }
}
