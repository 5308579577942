import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tag-form"
export default class extends Controller {
  static targets = [
    'tagsSource',
    'tagsSpoof',
    'tagsInput',
    'submitBtn'
  ]

  /**
   * The actual input field being submitted. It is visually hidden from the user,
   * but we alter it as the user adds or subtracts tags.
   */
  #tagsSource

  /**
   * A div that looks like an input field, but allows for stuffing
   * tag elements inside
   */
  #tagsSpoof

  /**
   * An input field (without any styling) which allows the user to type
   * in new tags
   */
  #tagsInput

  /**
   * An array representing the current tags selected by the user
   */
  #tags

  /**
   * The submit button for the Post form
   */
  #submitBtn

  connect () {
    this.#tagsSpoof = this.tagsSpoofTarget
    this.#tagsSource = this.tagsSourceTarget
    this.#tagsInput = this.tagsInputTarget
    this.#submitBtn = this.submitBtnTarget
    this.#tags = []

    // Grab the value from the hidden input and build
    // our array off of it
    if (this.#tagsSource.value.length > 0) {
      this.#tags = this.#tagsSource.value.split(',')
      this.#tags = this.#clean(this.#tags)
    }

    // Respond to an Enter keystroke while in the tag input
    this.#tagsInput.addEventListener('keypress', this.#handleTagEnter)
    this.#submitBtn.addEventListener('click', this.#processFormSubmission)

    // Exit if there are no tags or not an array
    if (!Array.isArray(this.#tags) || !this.#tags.length) return false

    // Add visual tags to the tags element
    for (let i = 0; i < this.#tags.length; i++) {
      const tagElement = this.createTag(this.#tags[i])
      this.#tagsSpoof.prepend(tagElement)
    }
  }

  /**
   * Creates a visual tag DOM element based on the text provided.
   * @param {string} text - The name of the new tag
   * @returns a visual tag DOM element
   */
  createTag (text) {
    const tagElement = document.createElement('div')
    tagElement.classList.add('btn', 'btn-primary', 'me-1', 'mb-1')
    tagElement.innerHTML += `${text} `
    tagElement.tagString = text

    const closeBtn = document.createElement('span')
    closeBtn.classList.add('badge', 'bg-secondary')
    closeBtn.innerHTML += 'x'
    closeBtn.addEventListener('click', this.removeTag)

    tagElement.appendChild(closeBtn)

    return tagElement
  }

  /**
   * Remove both the visual tag AND update the value of the hidden form field.
   * @param {event} e
   */
  removeTag = e => {
    const visualTag = e.currentTarget.parentElement
    // Remove the visual representation
    visualTag.remove()
    // Update our tags array
    this.#tags = this.#tags.filter(tag => tag !== e.target.parentElement.tagString)
    // Set the value of the form field to our tag array
    this.#tagsSource.value = this.#tags
  }

  /**
   * Handle an Enter keypress when adding a new Tag.
   * @param {event} e
   */
  #handleTagEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.#processNewTags()
    }
  }

  // Needs to run on Enter or submit
  #processNewTags = _ => {
    let newTags = this.#tagsInput.value.split(',')
    newTags = this.#clean(newTags)
    if (newTags.length < 1) return false
    // Remove any redundant tags
    newTags = newTags.filter(tag => this.#tags.indexOf(tag) < 0)
    // Add new tag element
    newTags.forEach(tag => {
      const tagElement = this.createTag(tag)
      this.#tagsSpoof.append(tagElement)
    })
    // Make sure our input is always last
    this.#tagsSpoof.append(this.#tagsInput)
    // Clear out the input
    this.#tagsInput.value = null
    // Combine the new tags with existing
    this.#tags = this.#tags.concat(newTags)
    // Cleanse all tags
    this.#tags = this.#clean(this.#tags)
    // Set the value of the form field to our tag array
    this.#tagsSource.value = this.#tags
  }

  /**
   * Accepts a new array of tags and returns a trimmed and deduped array
   * @param {Array<string>} tagArray - A new array of tags you want to add to existing tags
   * @returns an array of tags with whitespace removed and deduped with existing tags
   */
  #clean = tagArray => {
    tagArray = tagArray.map(tag => tag.trim())
    tagArray = tagArray.filter(tag => tag.trim().length > 0)
    return tagArray
  }

  #processFormSubmission = _ => {
    this.#processNewTags()
  }
}
