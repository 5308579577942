import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.adjustHeight()
  }

  adjustHeight = _ => {
    const element = this.element
    const value = this.element.value

    element.style.height = (element.scrollHeight) + 'px';

    // Convert aside tags to markdown
    const valueWithSwappedAsides = value.replace(/<aside>(?<content>[\s\S]*?)<\/aside>/, this.#stripAsides)
    this.element.value = valueWithSwappedAsides
  }

  #stripAsides = (match, content) => {
    const modifiedContent = content.trim().replace(/\n/gm, this.#addGreaterThans)

    // Uses the preferred callout syntax
    return '> [!NOTE]\n' +
      `> ${modifiedContent.trim()}\n`
  }

  // Add ">" to each no line to conform to the preferred callout syntax
  #addGreaterThans = (match, content) => {
    return '\n> '
  }
}
