// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'

// Use this line in your Stimulus controllers if you need Bootstrap's JavaScript
// import * as bootstrap from 'bootstrap'

import LocalTime from 'local-time'
LocalTime.start()

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
